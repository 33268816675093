import { useEffect, useRef, useState } from "react";
import {useParams, useNavigate} from "react-router-dom";
import style from '../css/UserResult.module.css';
import templateCss from '../css/template.module.css';
import { useDispatch, useSelector } from "react-redux";
import Api from "../helperFunctions/Api";
import parse from 'html-react-parser';
import {checkCurlyBracket} from '../helperFunctions/resultCalulator';
import Modal from '../components/modal';
import Loading from '../components/loading';
import { MdArrowBackIos } from "react-icons/md";
import { setUserResult2 } from '../Redux/Reducers/resultReducer';


const UserResult = () =>{
    const results = useSelector(store => store.results.userResult);
    const dispatch = useDispatch();
    const cookie = localStorage.getItem('eduplus-user88');
    const [selResult, setSelResult] = useState(null);
    const [selResult2, setSelResult2] = useState(null);
    const [template, setSelTemplate] = useState(null);
    const [resultType, setResultType] = useState('');
    const [resultSet, setResultSet] = useState('');
    const [resultGroup, setResultGroup] = useState('');
    const [userRes, setUserResult] = useState(-1);
    const [shouldShowResult, setShouldShowResult] = useState(0);
    const [modalText, setModalText] = useState('');
    const [modalToggle, setModalToggle] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [ui, setui] = useState(0);
    const navigate = useNavigate();

    const selectResult =(result, template_id)=>{
        //select result here
        setSelResult(result);
        setSelTemplate(JSON.parse(results.template.find(temp => temp.id === template_id).template))
    }
    useEffect(() => {
        //console.log(cookie, 'cookie')
        if(cookie){
            dispatch(setUserResult2(JSON.parse(cookie)));
        }else navigate('/')
    }, [cookie.user, dispatch])

    const writePosition = (pos) =>{
        console.log(pos,'pos')
        const lastTxt = pos.toString().slice(-1)
        console.log(lastTxt, 'lastTxt');
        switch(lastTxt){
            case '1':
                return 'st';
            case '2':
                return 'nd';
            case '3':
                return 'rd'
            default:
                return 'th';
        }
    }

    const showResult = () =>{
        setui(1); 
        const usResult = results.result[resultSet].groups[resultGroup].userResults[userRes];
        setSelResult(usResult);
        const template_id = results.result[resultSet].template_id;
        let template = JSON.parse(results.template.find(temp => temp.id === template_id).template).sections;
        setSelTemplate(template)

        if(usResult.result.length > 0){
            let mainRes = JSON.parse(usResult.result);
        let groupRes = JSON.parse(results.result[resultSet].groups[resultGroup].result);

        for(let x in groupRes){
            let txSplit = x.split('_');
            let pos = txSplit[0].split(':');
            let posS = txSplit[1].split(':');
            let formula = template[`${posS[0]}`].content[`${posS[2]}`].extra;
            let func; let func2;

            //console.log(formula, 'formula')
            if(formula.split('[').length > 1){
                func = formula.split('{')[0];
                func2 = formula.split('{')[1].split('[')[0];
            }
            else{
                func = formula.split('{')[0];
            }

            let gRes;
            //console.log(func, 'func')
            switch(func){
                case 'GHIGHEST':          
                    if(func2 == 'CTOTAL'){
                        gRes = 0;
                        for(let y in groupRes[x]){
                             let indTotal = 0; let zpt;
                            for(let z in groupRes[x][y]){
                                zpt = z.split(':')[1];
                                indTotal += Number(groupRes[x][y][z])
                            }

                            if(indTotal > gRes){
                                gRes = indTotal
                                mainRes[`${posS[0]}`][Number(zpt)][`${posS[2]}`] = indTotal;
                            }
                        }
                    }
                    else{
                        let indTotal = {};
                        for(let y in groupRes[x]){
                            for(let z in groupRes[x][y]){
                                const zpt = z.split(':');
                                
                                if(indTotal[`${z}`]){
                                    if(Number(groupRes[x][y][z]) > indTotal[`${z}`]){
                                        indTotal[`${z}`] = Number(groupRes[x][y][z]);
                                        mainRes[`${posS[0]}`][Number(zpt[1])][`${posS[2]}`] = Number(groupRes[x][y][z]);
                                    }
                                }
                                else{
                                    indTotal[`${z}`] = Number(groupRes[x][y][z]);
                                    mainRes[`${posS[0]}`][Number(zpt[1])][`${posS[2]}`] = Number(groupRes[x][y][z]);
                                }     
                            }
                        }
                    }
                    break;
                case 'GPOSITION':
                    if(func2 == 'CTOTAL'){
                        let stuScore = {}; let myScore; let zpt;
                        for(let y in groupRes[x]){
                             let indTotal = 0; 
                            for(let z in groupRes[x][y]){
                                zpt = z.split(':')[1];
                                indTotal += Number(groupRes[x][y][z])
                            }
                            //indTotal *= -1;
                            if(!stuScore[`${indTotal}`])
                                stuScore[`${indTotal}`] = {}

                            if(Number(y) == selResult.id){
                                myScore = indTotal;
                            }
                        }

                        let stuArry = []
                        console.log(stuScore, 'stuScore')
                        for(let idy in stuScore){
                            stuArry.push(idy);
                        }
                        console.log(stuArry, 'stuArray')
                        let spos = 1;
                        for(let i = stuArry.length; i > 0; i--){
                            stuScore[`${stuArry[i-1]}`].poss = spos;
                            spos++;
                        }

                        console.log(myScore, 'myscore')
                        if(myScore)
                            mainRes[`${posS[0]}`][0][`${posS[2]}`] = stuScore[`${myScore}`].poss + writePosition(stuScore[`${myScore}`].poss);
                    }
                    else{
                        let stuScore = {};

                        for(let y in groupRes[x]){
                            for(let z in groupRes[x][y]){
                                const zpt = z.split(':');

                                if(stuScore[`${zpt[1]}`]){
                                    if(stuScore[`${zpt[1]}`][`${groupRes[x][y][z]}`])
                                        stuScore[`${zpt[1]}`][`${groupRes[x][y][z]}`].studId.push(y);
                                    else{
                                        stuScore[`${zpt[1]}`][`${groupRes[x][y][z]}`] = {}
                                        stuScore[`${zpt[1]}`][`${groupRes[x][y][z]}`].studId = [y];
                                    }
                                }
                                else{
                                    stuScore[`${zpt[1]}`] = {};
                                    stuScore[`${zpt[1]}`][`${groupRes[x][y][z]}`] = {};
                                    stuScore[`${zpt[1]}`][`${groupRes[x][y][z]}`].studId = [y];
                                }
                            }
                        }

                        for(let east in stuScore){
                            let allScore = []
                            for(let east1 in stuScore[east]){
                                allScore.push(Number(east1));     
                            }
                            let cnt = 1;
                            for(let i = allScore.length; i > 0; i--){
                                stuScore[east][allScore[i-1]].pos = cnt;
                                cnt++;
                            }

                            /*let preXC = Number.NEGATIVE_INFINITY; let stuPos = 0;
                            for(let idy in allScore){
                                if(Number(idy) != preXC){
                                    stuPos++;
                                    preXC = Number(idy);
                                    allScore[idy] = stuPos;
                                }
                            }*/
                            let mv = stuScore[east][`${mainRes[pos[0]][Number(east)][pos[2]]}`].pos;
                            mainRes[`${posS[0]}`][Number(east)][`${posS[2]}`] = mv + writePosition(mv)//allScore[`${mSVal}`];
                        }
                    }
                    break;
            }
        }

        //console.log(mainRes, 'result')

        for(let x in mainRes){
            const len = mainRes[x].length;

            for(let i = 0; i < len; i++){
                let colLen = Object.keys(template[x].content).length;
                for(let j = 0; j < colLen; j++){
                    const colType = template[x].content[`${j}`].type;
                    if(colType =='comtxt'){
                        if(!mainRes[x][i][`${j}`]){
                            mainRes[x][i][j] = checkCurlyBracket(template[x].content[`${j}`].extra,{
                                section: x, row: i, column: j
                            }, mainRes)
                        }
                    }
                    else if (colType == 'infdtxt'){
                        if(!mainRes[x][i][`${j}`]){
                            let conditions = JSON.parse(template[x].content[`${j}`].extra);
                            let values = JSON.parse(template[x].content[`${j}`].extraValue);

                            const conLen = conditions.length;
                            for(let k = 0; k < conLen; k++){
                                let isTrue = Number(checkCurlyBracket(conditions[k],{
                                    section: x, row: i, column: j
                                }, mainRes,true)) == 1 ? true : false;

                                if(isTrue){
                                    mainRes[x][i][`${j}`] = values[k]
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        }

        setSelResult2(mainRes);
        }

        if(results.result[resultSet].payment_link){
            if(usResult.as_paid) setShouldShowResult(1);
            else setShouldShowResult(2);
        }
        else setShouldShowResult(1);
    }

    const payNow = ()=>{
        setShowLoading(true);
        console.log(results.result[resultSet]) 
        const obj = {
            payment_link_id: results.result[resultSet].payment_link,
            user_email: selResult.email,
            purpose: `Payment for ${resultType}`,
            tab: "result",
            table_id: selResult.id
        }
        console.log(obj, 'payload')
        Api.PostApi(`/payment/service-payment`,obj,false)
        .then(result =>{
            console.log(result, 'pay now result')
            setShowLoading(false);
            
            console.log(result,'result');
            if(result.isSuccessful){    
                window.open(result.data.data.link,'_blank');
            }
            else{
                setModalText(result.message);
                setModalToggle(true);
            }
        })
        .catch(error =>{
            //setShowLoading(false);
            console.log(error,"Server Error");
        })
    } 

    const getCertificatesValues = (text, objValues)=>{
        let allVar = text.split('{');
        let allVarLen = allVar.length;
        
        for(let i = 1; i < allVarLen; i++){
            allVar[i-1] = allVar[i].split('}')[0]
        }
        allVar.pop();

        let alvLen = allVar.length;

        for(let i = 0; i < alvLen; i++){
            text = text.replace(`{${allVar[i]}}`, objValues[`${i}`]);
        }
        //setCertificate(allVar);
        return text;
    }

    return (
        <>
            <Loading shouldShow={showLoading} />
            <Modal modalObject={{body: modalText}} modalTogglee={modalToggle} closeModal={()=>setModalToggle(false)}/>
            <div>
                <div class={style.navbar}>
                    <label><img src=""/><span>EduPlus</span></label>
                    {ui > 0 && <label className={style.backBtn} onClick={()=>setui(0)}><span><MdArrowBackIos /></span>Back</label>}
                    <div class={style.rightpanel}><span>{localStorage.getItem('edures_em')}</span>
                    <span onClick={()=>{
                        localStorage.removeItem('eduplus-user88');
                        navigate('/')
                    }}>LogOut</span></div>
                </div>
                <div className={style.content}>
                    {
                        ui == 0 ?
                        <div className={style.selectResult}>
                            {results.result && <div>
                                <span>Select Result Set/Session</span><br/>
                                <select onChange={(e)=>setResultSet(e.target.value)}>
                                        <option value=''>Select Result Set/Session</option>
                                    {
                                        Object.values(results.result).map((set,index)=>(
                                            <option value={set.id} key={index}>{set.title}</option>
                                        ))
                                    }
                                </select>
                            </div>}
                            <div>
                                <span>Result Type</span><br/>
                                
                                <select onChange={e=>{
                                    setResultType(e.target.value); 
                                    }}>
                                    <option value=''>Select Result Type</option>
                                    <option value="result">Result</option>
                                    <option value="transcript">Transcript</option>
                                    <option value="certificate">Certificate</option>
                                </select>
                            </div>
                                {
                                    (resultType === 'result' && resultSet.length > 0) && <>
                                    <div>
                                        <span>Select Result group</span><br/> 
                                        <select onChange={e=>setResultGroup(e.target.value)}>
                                            <option value=''>Select Result Group</option>
                                            {
                                                Object.values(results.result[resultSet].groups).map((groups,index)=>(
                                                    <option key={index} value={groups.id}>{groups.title}</option>
                                                ))
                                            }
                                        </select> 
                                    </div> 
                                    </>
                                }
                                {
                                    (resultType.length > 0 && resultGroup) && 
                                    <div>
                                        <span>Select Result</span><br/>
                                        <select onChange={e=>setUserResult(e.target.value)}>
                                            <option value=''>Select Result</option>
                                            {
                                                results.result[resultSet].groups[resultGroup].userResults.map((userRess, index)=>(
                                                    <option key={index} value={index}>Result {index + 1}</option>
                                                ))
                                            }
                                        </select>  
                                    </div>
                                }
                                <br/>
                                {resultType.length > 0 && <button className={style.submitButton} onClick={showResult}>Show {resultType}</button>}
                            
                        </div> :
                        <div className={style.userResult}>
                        {
                            (selResult && template) && (
                                <>{
                                    (shouldShowResult == 1) ? (
                                        Object.values(template).map((section, index)=>(
                                            <div key={index} className={style.resDiv}>
                                                <h2>{section.name}</h2>
                                                {
                                                    section.type == 'table' ? (
                                                        <div className={templateCss.ColDiv}>
                                                            <table className={templateCss.table}>
                                                                <thead>
                                                                    <tr>{
                                                                            Object.values(section.content).map((column, index)=>(
                                                                                <th key={index}>
                                                                                    {column.name}
                                                                                </th>
                                                                            ))
                                                                        }
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {selResult2[section.id].map((row, rowIndex)=>(
                                                                        <tr key={rowIndex}>
                                                                        {
                                                                            Object.values(section.content).map((column, index)=>(
                                                                                <td key={index}>{
                                                                                    column.type=='img' &&
                                                                                    <img height={100} src={selResult2[section.id][rowIndex][index]} />
                                                                                    }
                                                                                    {
                                                                                        (column.type == 'txt'|| column.type == 'comtxt' || column.type == 'course' || column.type=='users' || column.type == 'infdtxt')
                                                                                         && 
                                                                                        <span>{selResult2[section.id][rowIndex][index]}</span>
                                                                                    }
                                                                                    {
                                                                                        column.type == 'fixedtxt' &&
                                                                                        <span>{column.extra}</span>
                                                                                    }
                                                                                    {
                                                                                        column.type == 'fixedimg' && 
                                                                                        <img height={100} src={column.extra} />
                                                                                    }
                                                                                </td>
                                                                            ))
                                                                        }
                                                                    </tr>
                                                                    ))                         
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    ) : (
                                                        section.type == 'comment' ? 
                                                        <div className={style.viewComment}>
                                                            {
                                                                Object.values(section.content).map((column,index)=>(
                                                                    <div key={index}>
                                                                        <span>{column.name}:</span>
                                                                            {
                                                                                 column.type=='img' &&
                                                                                 <img height={100} src={selResult2[section.id][0][index]} />
                                                                            }
                                                                            {
                                                                                (column.type == 'txt'|| column.type == 'comtxt' || column.type == 'courses' || column.type=='users' || column.type == 'infdtxt')
                                                                                && 
                                                                                <span className={style.line}>{selResult2[section.id][0][index]}</span>
                                                                            }
                                                                            {
                                                                                column.type == 'fixedtxt' &&
                                                                                <span className={style.line}>{column.extra}</span>
                                                                            }
                                                                            {
                                                                                column.type == 'fixedimg' && 
                                                                                <img height={100} src={column.extra} />
                                                                            }    
                                                                        <br/>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div> :
                                                        <div className={style.certificate}>
                                                            {parse(getCertificatesValues(section.content['0'].name, selResult2[section.id][0]))
                                                        }
                                                    </div>
                                                    )
                                                }
                                            </div>
                                    ))) : (
                                        <div className={style.notpaid}>
                                            <span>You are yet to pay for this result</span> <br/>
                                            <a onClick={payNow}>Pay Now</a>
                                        </div>
                                    )
                                }</>
                            )
                        }
                        </div>
                    }
                    
                </div>
                
            </div>
        </>
    )
}

export default UserResult