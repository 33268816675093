import React from 'react';
import logo from '../images/edu-logo.png';
import logoCss from '../css/logo.module.css';

const Logo_img = ({ width, smallScreen }) => {
  const alt = "logo";
  return (
    <React.Fragment>
      <span className={logoCss.logo_container}>
        <img src={logo} alt={alt} width={width} />
        {smallScreen ? "" : <span>EDUPLUS RESULT</span>}

      </span>
    </React.Fragment>
  );
};

Logo_img.defaultProps = {
  width: 35,
};

export default Logo_img;