import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserResult2 } from "../Redux/Reducers/resultReducer";
import { useCookies } from "react-cookie";
import style from '../css/Result.module.css'
import 'react-icons/bs'
import { BsCaretDownFill, BsCaretRightFill } from "react-icons/bs";
import logo from '../images/edu-logo.png';
import { useReactToPrint } from 'react-to-print';
/* {
    "result": {
        "2": {
            "id": 2,
            "is_paid": 0,
            "title": "School result set",
            "description": "This is 2023/2024 session",
            "template_id": 5,
            "payment_link": null,
            "session": "2023/2024",
            "created_at": "2023-11-07T20:48:40.000Z",
            "groups": {
                "3": {
                    "title": "Level 1 Result",
                    "institution_id": null,
                    "institution_categories": null,
                    "description": "This is result for level 1",
                    "result": null,
                    "userResults": [
                        {
                            "id": 7,
                            "subuser_id": null,
                            "email": "john.mike@gmail.com",
                            "user_details": "John mike",
                            "created_at": "2023-11-22T01:00:01.000Z",
                            "user_reg_no": "Eng111",
                            "as_paid": null,
                            "result": "{\"1\":[{\"0\":\"PHY111\",\"1\":10,\"2\":10,\"3\":65}]}"
                        }
                    ]
                }
            }
        }
    },
    "template": [
        {
            "id": 5,
            "title": "First term result",
            "template": "{\"name\":\"First term result\",\"sections\":{\"0\":{\"id\":0,\"name\":\"Student details\",\"type\":\"comment\",\"isDefault\":1,\"columnRowLen\":[0],\"content\":{\"0\":{\"id\":0,\"name\":\"Student name\",\"type\":\"\",\"extra\":\"\",\"extraValue\":\"\"},\"1\":{\"id\":1,\"name\":\"Student ID\",\"type\":\"text\"}}},\"1\":{\"id\":1,\"name\":\"Result\",\"type\":\"table\",\"isDefault\":1,\"columnRowLen\":[0],\"content\":{\"0\":{\"id\":0,\"name\":\"Course\",\"type\":\"course\",\"extra\":\"\",\"extraValue\":\"\"},\"1\":{\"id\":1,\"name\":\"1st CA\",\"type\":\"text\"},\"2\":{\"id\":2,\"name\":\"2 CA\",\"type\":\"text\"},\"3\":{\"id\":3,\"name\":\"Exam\",\"type\":\"text\"},\"4\":{\"id\":4,\"name\":\"Total\",\"type\":\"comtxt\",\"extra\":\"{S:R:2}+{S:R:3}+{S:R:4}\"}}},\"2\":{\"id\":2,\"name\":\"Remarks\",\"type\":\"comment\",\"isDefault\":1,\"columnRowLen\":[0],\"content\":{\"0\":{\"id\":0,\"name\":\"Teachers Comment\",\"type\":\"\",\"extra\":\"\",\"extraValue\":\"\"},\"1\":{\"id\":1,\"name\":\"Principal Comment\",\"type\":\"text\"}}}},\"id\":4332897087}"
        }
    ]
} */
const UserResult = () => {
    const results = useSelector(store => store.results.userResult);
    const dispatch = useDispatch();
    const [cookie] = useCookies(['user']);
    const [selResult, setSelResult] = useState(null);
    const [selTemplate, setSelTemplate] = useState(null);

    const selectResult =(result, template_id)=>{
        setSelResult(result);
        setSelTemplate(JSON.parse(results.template.find(temp => temp.id === template_id).template))
    }
    useEffect(() => {
    }, [results])
    useEffect(() => {
        dispatch(setUserResult2(cookie.user));
    }, [cookie.user, dispatch])
    return (
        <div className={style.container}>
            <nav className={style.nav}>
                <div className={style.logo}>
                    <img src={logo} alt='eduplus-logo' />
                    <h3>Eduplus Result</h3>
                </div>
                {results.result && Object.values(results.result).map((set, key) => <Set set={set} key={'set' + key} >
                    {Object.values(set.groups).map((group, key) => <Set set={group} key={'group' + key}>
                        {group.userResults.map((result, key) => <button onClick={(e)=>selectResult(result, set.template_id)} key={'result' + key} className={style.navItemTitle+' '+style.result} style={selResult && selResult.id === result.id?{fontWeight:500, color:'var(--logo-color)'}:{}}>Result &nbsp;{key+1} </button>)}
                    </Set>)}
                </Set>)}
            </nav>
            <DisplayResult result={selResult} template={selTemplate}/>
        </div>
    )
}

const Set = ({set, children}) => {
    const [open, setOpen] = useState(false);

    return (<div className={style.navItems}>
    <button onClick={(e)=>{setOpen(!open); }} title={set.description} className={`${style.navItemTitle}`}>{open? <BsCaretDownFill />:<BsCaretRightFill /> }{set.title}</button>
    <div className={style.children}>{open && children}</div>
</div>)
}

const DisplayResult = ({result, template}) =>{
    const compRef = useRef();
    const handlePrint = useReactToPrint({
        content: ()=>compRef.current,
        documentTitle: "Eduplus Result",
    })

    return result && template ?
    <section className={style.resultSection} >
        <div className={style.resultDiv} ref={compRef}>
            <div>Name: {result.user_details}</div>
            <div>Email: {result.email}</div>
            <div>ID: {result.user_reg_no}</div>

            {Object.keys(JSON.parse(result.result)).map((key) => <Section section={template.sections[key]} data={JSON.parse(result.result)[key]} key={'section'+key} />)}
        </div>
        <div className={style.resultButton}>
            <button onClick={handlePrint} className={style.button25}>Print Result</button>
        </div>
    </section>
    :<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>Select Result</div>
}
const Section = ({section, data}) => {
    console.log(section, data)

    return section.type === 'table'?<table className={style.table}>
        <thead>
            <tr>{Object.values(section.content).map(content => <th key={content.id}>{content.name}</th>)}</tr>
        </thead>
        <tbody>
            {data.map((row, key) => <tr key={key}>
                {Object.values(section.content).map(content => <td key={content.id}>{ row[content.id]}</td>)}
            </tr>)}
        </tbody>
    </table>
    :''
}

export default UserResult;