import { createSlice } from "@reduxjs/toolkit";

export const resultSlice = createSlice({
    name: 'results',
    initialState: {
        userResult:{}
    },
    reducers: {
        setUserResult2: (state, action)=>{
            state.userResult = action.payload;
        }
    }
})

export const {
    setUserResult2
} = resultSlice.actions

export default resultSlice.reducer;