import LoginCom from '../components/Login';
import styles from './../css/MainLogin.module.css';
import { Link } from 'react-router-dom';
import LogoImg from '../components/Logo_img';

const Login = () => {
    return (
        <div className={`${styles.main}`}>
            <div className={`${styles.navDiv}`}>
                <div className={`${styles.nav}`} >
                <Link to='/'><LogoImg width='100' /></Link>
      </div></div>
            <div className={`${styles.heroDiv}`}></div>
            <div className={`${styles.loginDiv}`}><LoginCom /> </div>
            <div className={`${styles.footer}`}>Copyright &copy; 2024 Sight-EduPlus</div>
        </div>
    );
};
export default Login;