import './css/App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from "./pages/MainLogin";
import UserResult from './pages/Results';
import { Provider } from 'react-redux';
import store from './Redux/store';
import { CookiesProvider, useCookies } from 'react-cookie';
import Certificate from "./pages/Certificate";
import UserResult2 from './pages/UserResult';

function App() {
  const [cookies] = useCookies(["user"]);

  return (
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path="/account/:id" element={<Login />} />
            <Route path="/result/:id" element={<Certificate />} />
            <Route path="/user-results/:id" element={<Login />} />
            <Route path="/user-results/:id/*" element={<Login />} />
            <Route path="/user-results" element={<UserResult2 />} />
            <Route path="*" element={cookies.user ? <UserResult2 /> : <Login />} />
          </Routes>
        </Router>
      </Provider>
    </CookiesProvider>
  );
}

export default App;