import {useParams} from "react-router-dom";
import style from '../css/certificate.module.css';
import templateCss from '../css/template.module.css';
import Loading from '../components/loading';
import { useState, useEffect } from "react";
import Api from '../helperFunctions/Api';
import Modal from "../components/modal";
import parse from 'html-react-parser';

const Certificate = () =>{
    const [showloading, setShowLoading] = useState(true);
    const [modalText, setModalText] = useState('');
    const [modalToggle, setModalToggle] = useState(false);
    const [selResult, setSelResult] = useState(null);
    const [selResult2, setSelResult2] = useState(null);
    const [template, setSelTemplate] = useState(null);
    const [shouldShowResult, setShouldShowResult] = useState(0);

    const {id} = useParams();

    useEffect(()=>{
        Api.getApi('/result/get-result/' + id, false)
        .then(result=>{
            setShowLoading(false);
            console.log(result, 'result')
            if(result.isSuccessful){
                if(result.data.result.payment_link){
                    if(result.data.result.as_paid) setShouldShowResult(1);
                    else setShouldShowResult(2);
                }
                else setShouldShowResult(1);
                setSelResult2(JSON.parse(result.data.result.result));
                setSelResult(result.data.result);
               
                setSelTemplate(JSON.parse(result.data.template.template).sections)
            }
            else{
                setModalText(result.message);
                setModalToggle(true); 
            }
        })
    },[])

    const payNow = ()=>{
        setShowLoading(true);
        const obj = {
            payment_link_id: selResult.payment_link,
            user_email: selResult.email,
            purpose: `Payment for certificate`,
            tab: "result",
            table_id: selResult.id
        }
        console.log(obj, 'payload')
        Api.PostApi(`/payment/service-payment`,obj,false)
        .then(result =>{
            console.log(result, 'pay now result')
            setShowLoading(false);
            
            console.log(result,'result');
            if(result.isSuccessful){    
                window.open(result.data.data.link,'_blank');
            }
            else{
                setModalText(result.message);
                setModalToggle(true);
            }
        })
        .catch(error =>{
            //setShowLoading(false);
            console.log(error,"Server Error");
        })
    } 

    const getCertificatesValues = (text, objValues)=>{
        let allVar = text.split('{');
        let allVarLen = allVar.length;
        
        for(let i = 1; i < allVarLen; i++){
            allVar[i-1] = allVar[i].split('}')[0]
        }
        allVar.pop();

        let alvLen = allVar.length;

        for(let i = 0; i < alvLen; i++){
            text = text.replace(`{${allVar[i]}}`, objValues[`${i}`]);
        }
        //setCertificate(allVar);
        return text;
    }

    return (
        <>
            <Loading shouldShow={showloading} />
            <Modal modalObject={{ header: '', footer: '', body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} />
            <div className={style.certcontent}>
                <div className={style.userResult} style={{paddingTop: '-50px'}}>
                {
                    (selResult && template) && (
                        <>{
                            (shouldShowResult == 1) ? (
                                Object.values(template).map((section, index)=>(
                                    <div key={index} className={style.resDiv}>
                                        <h2>{section.name}</h2>
                                        {
                                            section.type == 'table' ? (
                                                <div className={templateCss.ColDiv}>
                                                    <table className={templateCss.table}>
                                                        <thead>
                                                            <tr>{
                                                                    Object.values(section.content).map((column, index)=>(
                                                                        <th key={index}>
                                                                            {column.name}
                                                                        </th>
                                                                    ))
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {selResult2[section.id].map((row, rowIndex)=>(
                                                                <tr key={rowIndex}>
                                                                {
                                                                    Object.values(section.content).map((column, index)=>(
                                                                        <td key={index}>{
                                                                            column.type=='img' &&
                                                                            <img height={100} src={selResult2[section.id][rowIndex][index]} />
                                                                            }
                                                                            {
                                                                                (column.type == 'txt'|| column.type == 'comtxt' || column.type == 'course' || column.type=='users' || column.type == 'infdtxt')
                                                                                    && 
                                                                                <span>{selResult2[section.id][rowIndex][index]}</span>
                                                                            }
                                                                            {
                                                                                column.type == 'fixedtxt' &&
                                                                                <span>{column.extra}</span>
                                                                            }
                                                                            {
                                                                                column.type == 'fixedimg' && 
                                                                                <img height={100} src={column.extra} />
                                                                            }
                                                                        </td>
                                                                    ))
                                                                }
                                                            </tr>
                                                            ))                         
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            ) : (
                                                section.type == 'comment' ? 
                                                <div className={style.viewComment}>
                                                    {
                                                        Object.values(section.content).map((column,index)=>(
                                                            <div key={index}>
                                                                <span>{column.name}:</span>
                                                                    {
                                                                            column.type=='img' &&
                                                                            <img height={100} src={selResult2[section.id][0][index]} />
                                                                    }
                                                                    {
                                                                        (column.type == 'txt'|| column.type == 'comtxt' || column.type == 'courses' || column.type=='users' || column.type == 'infdtxt')
                                                                        && 
                                                                        <span className={style.line}>{selResult2[section.id][0][index]}</span>
                                                                    }
                                                                    {
                                                                        column.type == 'fixedtxt' &&
                                                                        <span className={style.line}>{column.extra}</span>
                                                                    }
                                                                    {
                                                                        column.type == 'fixedimg' && 
                                                                        <img height={100} src={column.extra} />
                                                                    }    
                                                                <br/>
                                                            </div>
                                                        ))
                                                    }
                                                </div> :
                                                <div className={style.certificate}>
                                                    {parse(getCertificatesValues(section.content['0'].name, selResult2[section.id][0]))
                                                }
                                            </div>
                                            )
                                        }
                                    </div>
                            ))) : (
                                <div className={style.notpaid}>
                                    <span>You are yet to pay for this result</span> <br/>
                                    <a onClick={payNow}>Pay Now</a>
                                </div>
                            )
                        }</>
                    )
                }
                </div>
            </div>
        </>
    )
}

export default Certificate;